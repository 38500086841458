<template>
    <section>
        <div class="container">
            <h2 class="pb-2">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>


            <div v-if="!items" class="row row-cols-1 g-4"  v-bind:class="[columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3']">
                <div  class="col" v-for="n in (parseInt(columnas) > 0 ? parseInt(columnas) : 3)" v-bind:key="n">
                    <div class="card loading-card-second {{n}}">
                        <div class="card-body rounded d-flex align-items-center">
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-cols-1 g-4 text-center" v-bind:class="[columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3']">
                <div class="col" v-for="( el, i ) in items" :key="i">
                    <div class="card text-bg-ptn-color-gsj-brand">
                        <div class="card-body">
                            <h3 class="my-0 fs-5">
                                <a v-bind:href="el.url" v-bind:target="el.target ? el.target : '_self' " class="stretched-link text-reset">{{ el.title }}</a>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios = require('axios');
export default {
    name: "CardGridComponent",
    props: {
        titulo: String,
        subtitulo : String,
        columnas: String,
        filtros : Object,
        data : Object
    },
    data() {
        return {
            hash: "CGC",
            items: null,
            title : null,
            desc : null
        }
    },
    mounted() {
        if(this.data == null){
            let params = {};
            if(this.filtros != null && this.filtros != ""){
                    params = this.filtros;
                }
                params.tipo = this.hash;
                params.current_page = this.current_page;
                params.results = this.results;
            axios.post(process.env.VUE_APP_API_URL,params)
            .then(response => {
                this.items = response.data ? response.data.items : null
                this.title = response.data ? response.data.title : null
                this.desc = response.data ? response.data.desc : null
                localStorage.setItem(this.hash, JSON.stringify(response));
            })
            .catch (error => {
                if (!error.response) {
                    console.log("Fuera de Linea");
                    if(localStorage.getItem(this.hash)){
                        console.log("Cargando elementos de cache para " + this.hash);
                        var resp = JSON.parse(localStorage.getItem(this.hash));
                        this.items = resp.data ? resp.data.items : null                    
                        this.title = resp.data ? resp.data.title : null
                        this.desc = resp.data ? resp.data.desc : null
                    }
                }
            })
        }else{
            this.items = this.data;
        }
    }
}
</script>
