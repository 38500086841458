<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 26,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <ITCGridComponent :columnas="3" :data="[
    {
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Consulta de Expediente',
      url: '?uid=CUMV-VIEW-ExpedientesComponent',
    },
    {
      icon: 'question_answer',
      target: '_self',
      title: 'Consultas y solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent',
    },
    {
      icon: 'find_in_page',
      target: '_self',
      title: 'Digesto Ministerial',
      url: 'https://digestos.sanjuan.gob.ar/',
    },
  ]" />

  <BoxIconGridComponent titulo="Accesos Rápidos" :data="[
    {
      desc: null,
      icon: 'ptn-i-obras-y-ss',
      target: '_blank',
      title: 'Conocé nuestras obras',
      url: 'http://sii.sanjuan.gob.ar/SIGOP/DESA/OBRAS/demo-home.html',
    },
    {
      desc: null,
      icon: 'ptn-i-ipv',
      target: '_blank',
      title: 'Instituto Provincial de la Vivienda',
      url: 'https://ipv.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: 'ptn-i-ag-sj-des-inv',
      target: '_self',
      title: 'Construcción Túnel de Zonda',
      url: '?uid=CUMV-licitacion-zonda',
    },
    {
      desc: null,
      icon: require('@/assets/iconos/mapa.png'),
      target: '_blank',
      title: 'Dirección de Planeamiento y Desarrollo Urbano',
      url: 'https://gop.sanjuan.gov.ar/autogestion/web/site/login',
    },
    {
      desc: null,
      icon: require('@/assets/iconos/gota.png'),
      target: '_blank',
      title: 'Departamento de Hidráulica',
      url: 'https://hidraulica.sanjuan.gob.ar/',
    },
  ]" />

  <NewsComponent titulo="Noticias" />

  <!--   <DocumentListComponent
    resultados="5"
    :show-fecha="false"
    titulo="Guía de Trámites"
    :filtros="{ site: 'dlc.tramites.obras' }"
  /> -->

  <DocumentListComponent resultados="5" :show-fecha="true" titulo="Licitaciones"
    :filtros="{ site: 'dlc.compras.obras' }" />

  <!-- <GridLinkComponent
    titulo="Instituto provincial de la vivienda"
    :data="[
      {
        title: 'Isla de Informes',
        subtitle: '(264) 430 5026',
        url: 'tel:2644305026',
      },
      {
        title: 'Mesa de entradas',
        subtitle: '(264) 430 5216/5217',
        url: 'tel:2644305217',
      },
      {
        title: 'Regularización Dominial',
        subtitle: '(264) 430 3326',
        url: 'tel:2644303326',
      },
      {
        title: 'Adjudicaciones',
        subtitle: '(264) 430 5199',
        url: 'tel:2644305199',
      },
      {
        title: 'Operatoria Individual (informes)',
        subtitle: '(264) 430 5025',
        url: 'tel:2644305025',
      },
      {
        title: 'Operatoria Individual (Asistencia Técnica)',
        subtitle: '(264) 430 5266',
        url: 'tel:2644305266',
      },
      {
        title: 'Gestión de Cartera (Pagos y Cancelaciones)',
        subtitle: '(264) 430 5281/5282',
        url: 'tel:2644305281',
      },
    ]"
  /> -->

  <!-- <section class="container pb-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-warning fade show p-4">
          <div class="row">
            <div class="col-lg-10"><strong>¡Atención!</strong> Turnos IPV</div>
            <div class="col-lg-2">
              <a
                href="https://turnos.sanjuan.gob.ar"
                class="btn btn-danger btn-block text-white"
                target="_blank"
                >Acceder</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <FrecuentAccessComponent titulo="" :data="[
    {
      title: 'Consultas',
      links: [
        {
          target: '_blank',
          title: 'Correo Web',
          url: 'https://correo.sanjuan.gob.ar/',
        },
      ],
    },

    {
      title: 'Plan de Ordenamiento Territorial',
      links: [
        {
          target: '_blank',
          title: 'PLAM SJ',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/pdf_obras/PLAMSJ.pdf',
        },
        {
          target: '_blank',
          title: 'ACTUALIZACIÓN 2023-PLAM SJ',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/pdf_obras/ACTUALIZACION%202023%20-%20PLAM%20SJ.pdf',
        },
        {
          target: '_blank',
          title: 'Plan Calingasta ',
          url: 'https://drive.google.com/drive/folders/124-hUSckxrfhCVh6mgtg2bmMDV6A3VB6?usp=drive_link',
        },
      ],
    },

    {
      title: 'Plan de Gestión Integral de los Recursos Hídricos',
      links: [
        {
          target: '_blank',
          title: 'Plan de Gestión Integral de los Recursos Hídricos',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/pdf_obras/Plan%20de%20Gestion%20Integral%20de%20los%20Recursos%20Hidricos.pdf',
        },

      ],
    },
    {
      title: 'Gestión 2015-2023',
      links: [
        {
          target: '_blank',
          title: 'OSSE Informe de Gestión 2020-2022',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/OSSE%20Informe%20de%20Gestion%202020-2022.pptx',
        },
        {
          target: '_blank',
          title: 'Vialidad Provincial Informe de Gestión 2016-2023',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/Vialidad%20Provincial%20Informe%20de%20Gestion%202016-2023.pdf',
        },
        {
          target: '_blank',
          title: 'Libro MOSP',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/LIBRO%20MOSP%20-%20Version%20Digital%20(2).pdf',
        },
      ],
    },
  ]" />

  <!--  <GridLinkComponent
    titulo="Dirección de planeamiento y desarrollo humano"
    :data="[
      {
        title: 'Dirección',
        subtitle: '(264) 430-5158/5160',
        url: 'tel:2644305158',
      },
      {
        title: 'Jefatura Técnica',
        subtitle: '(264) 430-5163',
        url: 'tel:2644305163',
      },
      {
        title: 'Secretaría Técnica',
        subtitle: '(264) 430-5164',
        url: 'tel:2644305164',
      },
      {
        title: 'Jefe Depto. Planeamiento',
        subtitle: '(264) 430-5162',
        url: 'tel:2644305162',
      },
      {
        title: 'Jefe Obras Particulares',
        subtitle: '(264) 430-5145',
        url: 'tel:2644305145',
      },
      {
        title: 'Planificación Territorial',
        subtitle: '(264) 430-7459',
        url: 'tel:2644307459',
      },
      {
        title: 'Mesa de Entradas',
        subtitle: '(264) 430-5149',
        url: 'tel:2644305149',
      },
      {
        title: 'Archivo 2° Subsuelo',
        subtitle: '(264) 430-5088',
        url: 'tel:2644305088',
      },
      {
        title: 'Depósito',
        subtitle: '(264) 430-5151',
        url: 'tel:2644305151',
      },
      {
        title: 'Línea y Nivel de Vereda',
        subtitle: ' (264) 430-5142',
        url: 'tel:2644305142',
      },
      {
        title: 'Vías Urbanas',
        subtitle: '(264) 430-5142',
        url: 'tel:2644305142',
      },
      {
        title: 'Factibilidad de Subdivisión',
        subtitle: '(264) 430-5138',
        url: 'tel:2644305138',
      },
      {
        title: 'Factibilidad de Construcción',
        subtitle: '(264) 430-5139',
        url: 'tel:2644305139',
      },
      {
        title: 'Factibilidad de Funcionamiento',
        subtitle: '(264) 430-5140/5141/7403',
        url: 'tel:2644305140',
      },
      {
        title: 'Asesoría Arquitectónica',
        subtitle: '(264) 430-5148/5154/7455',
        url: 'tel:2644305148',
      },
      {
        title: 'Asesoría Estructural',
        subtitle: '(264) 430-5152/5153/5144/5148',
        url: 'tel:2644305152',
      },
      {
        title: 'Final de Obra y Licencia de Uso',
        subtitle: '(264) 430-5166',
        url: 'tel:2644305166',
      },
      {
        title: 'Denuncias',
        subtitle: '(264) 430-6201/5109',
        url: 'tel:2644306201',
      },
      {
        title: 'Inspecciones',
        subtitle: ' (264) 430-5143/5146',
        url: 'tel:2644305143',
      },
      {
        title: 'Sellado de Planos y Permiso de Edificación',
        subtitle: '(264) 430-5153',
        url: 'tel:2644305153',
      },
      {
        title: 'Gestión y Procedimiento',
        subtitle: '(264) 430-3357/5159',
        url: 'tel:2644303357',
      },
      {
        title: 'Consulta Web',
        subtitle: '(264) 530-5159',
        url: 'tel:2645305159',
      },
      {
        title: 'Administrativo',
        subtitle: '(264) 430-5205/5156',
        url: 'tel:2644305205',
      },
      {
        title: 'Personal',
        subtitle: '(264) 430-5168',
        url: 'tel:2644305168',
      },
      {
        title: 'Factibilidad de Minería',
        subtitle: ' (264) 430-7403',
        url: 'tel:2644307403',
      },
      {
        title: 'Contable',
        subtitle: ' (264) 430-5150/5167',
        url: 'tel:2644305150',
      },
    ]"
  /> -->

  <FrecuentAccessComponent titulo="Contactos Organismos" :data="[
    {
      title: 'OSSE',
      links: [
        {
          target: '_blank',
          title: '0 800 222 6773',
          url: 'tel:08002226773',
        },
        {
          target: '_blank',
          title: 'Página Web',
          url: 'https://ossesanjuan.com.ar',
        },
      ],
    },

    {
      title: 'EPRE',
      links: [
        {
          target: '_blank',
          title: '0 800 333 6666',
          url: 'tel:08003336666',
        },
        {
          target: '_blank',
          title: 'Página Web',
          url: 'https://epresanjuan.gob.ar',
        },
      ],
    },

    {
      title: 'EPSE',
      links: [
        {
          target: '_blank',
          title: '(0264) 466 1600',
          url: 'tel:2644661600',
        },
        {
          target: '_blank',
          title: 'Página Web',
          url: 'https://www.epse.gob.ar',
        },
      ],
    },

    {
      title: 'Conservación - Talleres DPV',
      links: [
        {
          target: '_blank',
          title: '(0264) 421 2028',
          url: 'tel:2644212028',
        },
      ],
    },

    /* {
      title: 'HIDRÁULICA',
      links: [
        {
          target: '_blank',
          title: '(0264) 422 8396',
          url: 'tel:2644228396',
        },
        {
          target: '_blank',
          title: '(0264) 422 5039',
          url: 'tel:2644225039',
        },
        {
          target: '_blank',
          title: '(0264) 422 1962',
          url: 'tel:2644221962',
        },
        {
          target: '_blank',
          title: 'Radio: (0264) 422 3641',
          url: 'tel:2644223641',
        },
        {
          target: '_blank',
          title: 'Página Web',
          url: 'www.hidraulica.sanjuan.gov.ar',
        },
      ],
    }, */

    {
      title: 'DECSA',
      links: [
        {
          target: '_blank',
          title: '(0264) 496 1696',
          url: 'tel:2644961969',
        },
      ],
    },
  ]" />
  <AutoriryComponent :showProtocolo="false" :showLinks="true" :data="[
    {
      title: 'Fernando Perea',
      subtitle: 'Ministro de Infraestructura, Agua y Energía',
      imagen: require('@/assets/img/min_de_infraestructura.jpg'),
    },
  ]" />
</template>
