<template>
    <section>
        <div class="container">
            <h2 class="pb-2">{{ titulo != null ? titulo : this.title != null ? this.title : '' }}</h2>
            <div v-if="!items" class="row row-cols-1 g-4"  v-bind:class="[columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3']">
                <div  class="col" v-for="n in (parseInt(columnas) > 0 ? parseInt(columnas) : 3)" v-bind:key="n">
                    <div class="card loading-card {{n}}">
                        <div class="card-body rounded d-flex align-items-center">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 g-4"  v-bind:class="[columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3']">
                <div class="col" v-for="( el , i ) in items" :key="i">
                    <div class="card">
                        <div class="card-body rounded d-flex align-items-center">
                            <div class="icon-square d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                <svg v-if="el.icon.includes('ptn')" class="ptn-i-2x text-ptn-color-gsj-2" v-bind:class="[el.desc ? 'ptn-i-3x' : '']">
                                    <use v-bind="{'xlink:href' : '#' + el.icon }"></use>
                                </svg>
                                <span v-if="!el.icon.includes('ptn')" class="material-symbols-outlined text-ptn-color-gsj-2 ptn-i-2x" v-bind:class="[el.desc ? 'ptn-i-3x' : '']">{{ el.icon }}</span>
                            </div>
                            <div>
                                <div class="card-title" v-bind:class="[el.desc ? 'h3' : 'h4']" >
                                    <a v-bind:href="el.url?el.url:'#'" v-bind:target="el.target ? el.target : '_self' " class="stretched-link link-secondary text-ptn-color-gsj-2" >{{ el.title }}</a>
                                </div>
                                <p class="card-text" v-if="el.desc">{{ el.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>


<script>
const axios = require('axios');
export default {
    name: 'IconTitleCardComponent',    
    props: {
        titulo: String,
        subtitulo : String,
        columnas: String,
        filtros : Object,
        data : Object
    },
    data() {
        return {           
            hash : "ITCGC",
            items: null,
            title : null,
            desc : null
        }
    },
    mounted() {
        if(this.data == null){
            let params = {};
            if(this.filtros != null && this.filtros != ""){
                params = this.filtros;
            }
            params.tipo = this.hash;
            
            axios.post(process.env.VUE_APP_API_URL,params)
            .then(response => {
                this.items = response.data ? response.data.items : null
                this.title = response.data ? response.data.title : null
                this.desc = response.data ? response.data.desc : null
                localStorage.setItem(this.hash, JSON.stringify(response));
            })
            .catch (error => {
                if (!error.response) {
                    console.log("Fuera de Linea");
                    if(localStorage.getItem(this.hash)){
                        console.log("Cargando elementos de cache para " + this.hash);
                        var resp = JSON.parse(localStorage.getItem(this.hash));
                        this.items = resp.data ? resp.data.items : null                    
                        this.title = resp.data ? resp.data.title : null
                        this.desc = resp.data ? resp.data.desc : null
                    }
                }
            })
        }else{
            this.items = this.data;
        }
    }
}
</script>