<template>
  <div class="" style="min-height: 150px">
    <div
      style="text-align: justify; overflow-wrap: break-word"
      v-if="!customView && !isExpedientesComponent && !isFormComponent"
    >
      <div v-html="html"></div>
    </div>
    <div class="dinamic" v-if="customView">
      <component v-bind:is="customView"></component>
    </div>
    <FormComponent v-if="isFormComponent" />
    <ExpedientesComponent
      v-if="isExpedientesComponent"
      :filtros="{ site: 'dlc.siged.consulta.exp' }"
    />

    <div class="text-center pt-4">
      <p>
        <button v-on:click="back" class="btn btn-secondary text-uppercase">
          Volver
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExpedientesComponent from "./components/resources/ExpedientesComponent.vue";
import FormComponent from "./components/resources/FormComponent.vue";

export default {
  name: "InfoView",
  props: {
    uid: String,
    filtros: Object,
  },
  data() {
    return {
      html: null,
      customView: false,
      isExpedientesComponent: false,
      isFormComponent: false, // Agregada la propiedad isFormComponent
    };
  },
  methods: {
    back() {
      history.back();
    },
  },
  mounted() {
    if (this.uid === "CUMV-VIEW-FormComponent") {
      this.isFormComponent = true;
    } else if (this.uid === "CUMV-VIEW-ExpedientesComponent") {
      this.isExpedientesComponent = true;
    } else if (this.uid.includes("CUMV-"))
      switch (this.uid) {
        case "CUMV-licitacion-zonda": {
          this.html = ` <section>
            <div class="container">
                <h2 class="text-center text-secondary mb-3">Construcción Túnel de Zonda y sus accesos</h2>
                <div class="row">
                    <div class="col-lg-12">
                        <p><b> Sobre la obra </b></p>
                        <p>El proyecto "Construcción del Túnel de Zonda" es una obra estratégica que conectará a los departamentos de Rivadavia, Zonda y Ullum, sin atravesar la denominada “Quebrada de Zonda”. A través de la generación de un by pass, el nuevo túnel atravesará la sierra de Marquesado con una longitud de 1.100 metros y dos carriles de circulación, uno para ida y otro para vuelta, es decir para el flujo vehicular rápido y el de carga. Esto evitará la incompatibilidad con el tránsito lento y recreativo que tendrá la Quebrada, para gozar a pleno todos los beneficios que generará el Master Plan Turístico.</p>
                        <p>Cabe destacar que esta obra tiene un importante valor agregado, ya que permitirá que por su interior pase el “Acueducto Gran Tulum”, que tiene por objeto asegurar el abastecimiento de agua potable a una población futura superior al millón de habitantes.</p>
                        <p>Sumado a esto, el túnel permitirá a futuro el paso del gasoducto que dotará del servicio a Zonda y Ullum, así como el tendido de fibra óptica, y el paso de una línea eléctrica de media tensión, con importantes ahorros de longitud y logística.</p>
                        <p>Esta obra, cuenta con financiación del Banco Interamericano de Desarrollo, lograda a través del Fondo Fiduciario Federal de Infraestructura Regional, aprobada por la Cámara de Diputados de San Juan.</p>
                        <p>De acuerdo a lo previsto, la etapa constructiva del túnel, demandará más de 500 puestos de trabajo.</p>
                        <p><b>Etapas del proceso</b></p>
                        <p>El 23 de marzo de 2022, se llevó a cabo la apertura de sobres de la Licitación Pública Internacional 01/2022 para la Construcción del Túnel de Zonda y sus accesos. El acto licitatorio contó con la propuesta de una sola empresa: Queiroz Galvao Construction S.A. </p>
                        <p>Posteriormente, el 26 de mayo de 2022, se presentó la oferta económica de la empresa que construirá el túnel de Zonda y sus accesos. El acto licitatorio contó con la propuesta la única empresa que se había presentado: Queiroz Galvao Construction SA, con un presupuesto de $ 8.469.138.894,96.</p>
                        <p>El 2 de noviembre de 2022, se llevó a cabo la firma de dos actas importantes, por un lado el Acta de Inicio de Obra y el Acta Socioambiental.</p>
                        <div class="mt-4 mb-3 col-lg-4"><h3 class="px-4 py-2 rounded-top fs-5 text-bg-ptn-color-gsj-brand" data-v-79225ea2="">Consultas y sugerencias</h3><ul class="list-unstyled px-4" data-v-79225ea2=""><li class="mb-2" data-v-79225ea2=""><a class="fac-a multiline-link" href="mailto:consultas.tunelzonda@qgconstruction.com" target="_blank" data-v-79225ea2=""><!--v-if--><span class="material-symbols-outlined fac-span" data-v-79225ea2="">mail</span><!--v-if--> consultas.tunelzonda@qgconstruction.com</a><!--v-if--></li><li class="mb-2" data-v-79225ea2=""><a class="fac-a multiline-link" href="tel:2646289865" target="_blank" data-v-79225ea2=""><span class="material-symbols-outlined fac-span" data-v-79225ea2="">call</span><!--v-if--><!--v-if--> (264) 6289865</a><!--v-if--></li></ul></div>
                    </div>
                </div>
            </div>
            <div class="container ancla lead text-left" id="ancla-2 ">
                <h4 class="mt-5 ">Documentación</h4>
                <ul>        
          <li><a  href="https://sanjuan.gob.ar/docs/infraestructura/Plan%20de%20Manejo%20Ambiental%20y%20Social%20Oct.%202022-.pdf" target="_blank"> <b>Plan de Manejo Ambiental y Social Oct. 2022</b></a></li>     
					<li><a  href="http://sanjuan.gov.ar/docs/infraestructura/19_09_22_Notificacion_de_Adjudicacion_de_la_Obra_Tunel_de_Zonda_y_sus_Accesos.pdf" target="_blank"> <b>19/09/2022 - Notificación de Adjudicación</b></a></li>
          <li><a  href="https://sanjuan.gob.ar/docs/infraestructura/Declaraci%C3%B3n%20de%20Impacto%20Ambiental%20Res.%20N%C2%BA%20757-SEAyDS-2021%2017-09-21.pdf" target="_blank"> <b>17/09/2021 - Declaración de Impacto Ambiental</b></a></li>
          <li><a  href="https://sanjuan.gob.ar/docs/infraestructura/Estudio%20de%20Impacto%20Social%20y%20Ambiental_T%C3%BAnel%20de%20Zonda.pdf" target="_blank"> <b>01/10/2021 - Estudio de Impacto Social y Ambiental</b></a></li>     
          
          </ul>
            </div>
        </section>`;
          break;
        }
      }
    else {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.uid = this.uid;

      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.html = response.data ? response.data.html : null;
          localStorage.setItem(this.uid, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.uid)) {
              console.log("Cargando elementos de cache para " + this.uid);
              var resp = JSON.parse(localStorage.getItem(this.uid));
              this.html = resp.data ? resp.data.html : null;
            }
          }
        });
    }
  },
  components: { ExpedientesComponent, FormComponent },
};
</script>
